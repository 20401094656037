/* You can add global styles to this file, and also import other style files */
/*================================================
Default CSS
=================================================*/
:root {
    --fontFamily: 'Source Sans Pro', sans-serif;
    // --mainColor: #0ca59d;
    // --mainColor: #FBDA6F;
     --mainColor: #FBDA6F;
    --optionalColor: #666666;
    --whiteColor: #ffffff;
    --blackColor: #000000;
    --fontSize: 16px;
    --transition: .5s;
}
body {
    margin: 0;
    padding: 0;
    color: var(--blackColor);
    font: {
        size: var(--fontSize);
        family: var(--fontFamily);
    };
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    color: var(--blackColor);
}
a {
    color: var(--blackColor);
    transition: var(--transition);
    text-decoration: none;
    outline: 0 !important;

    &:hover {
        color: var(--mainColor);
        text-decoration: none;
    }
}
:focus {
    outline: 0 !important;
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
img {
    max-width: 90%;
    height: auto;
    // margin-top: -12px;
}
p {
    color: var(--optionalColor);
    font-size: var(--fontSize);
    margin-bottom: 15px;
    line-height: 1.8;

    &:last-child {
        margin-bottom: 0;
    }
}
.ptb-100 {
    padding: {
        // top: 100px;
        // bottom: 100px;
    };
}
.pt-100 {
    // padding-top: 100px;
    margin-top: 19px;
    // padding-top: 27px;
}
.pb-100 {
    padding-bottom: 100px;
}
.ptb-70 {
    padding: {
        top: 70px;
        bottom: 70px;
    };
}
.pt-70 {
    padding-top: 70px;
}
.pb-70 {
    // padding-bottom: 70px;
}
.container {
    max-width: 1230px;
  
}
.bg-f5f5f5 {
    // background-color: #f5f5f5;
}
.bg-e8fdff {
    background-color: #e8fdff;
}
.bg-fafafa {
    background-color: #fafafa;
}

/*section-title*/
.section-title {
    text-align: center;
    max-width: 720px;
    margin: {
        left: auto;
        right: auto;
        bottom: 60px;
        top: 34px;
    };
    .sub-title {
        display: block;
        font-weight: 600;
        margin-bottom: 12px;
    }
    h2 {
        font-size: 36px;
        margin-bottom: 12px;
    }
    p {
        max-width: 650px;
        margin: {
            left: auto;
            right: auto;
        };
    }
}

/*default-btn*/
.default-btn {
    border: none;
    position: relative;
    display: inline-block;
    // color: var(--whiteColor);
    color: var(--blackColor);
    box-shadow: unset !important;
    transition: var(--transition);
    background-color: var(--mainColor);
    padding: 13px 30px 12px 55px;
    font: {
        weight: 600;
        size: var(--fontSize);
    };
    i {
        top: 50%;
        left: 28px;
        font-size: 20px;
        position: absolute;
        margin-top: -1px;
        transform: translateY(-50%);
    }
    &:hover {
        // color: var(--whiteColor);
        color: #FBDA6F;
        background-color: var(--blackColor);
    }
}

/*form-control*/
.form-control {
    border-radius: 0;
    background-color: #f5f5f5 !important;
    box-shadow: unset !important;
    transition: var(--transition);
    border: none !important;
    height: 50px;
    padding-left: 15px;
    color: var(--blackColor);
    font: {
        size: var(--fontSize);
        weight: 500;
    };
    &::placeholder {
        color: #999999;
        transition: var(--transition);
    }
    &:focus {
        &::placeholder {
            color: transparent;
        }
    }
}
textarea.form-control {
    padding-top: 15px;
    height: auto;
}

/*owl-carousel-css*/
.home-slides {
    .owl-theme {
        .owl-nav {
            left: 80px;
            bottom: 0;
            margin-top: 0;
            position: absolute;

            [class*=owl-] {
                font-size: 25px;
                margin: 0 5px;
                padding: 0;
                width: 45px;
                height: 45px;
                background: #f5f5f5;
                position: relative;
                border-radius: 50%;
                color: var(--optionalColor);
                text-align: center;
                transition: var(--transition);

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &.owl-prev {
                    margin-left: 0;
                }
                &.owl-next {
                    margin-right: 0;
                }
                &:hover {
                    color: var(--whiteColor);
                    background-color: var(--mainColor);
                }
            }
        }
    }
}
.home-slides-two {
    .owl-theme {
        .owl-nav {
            margin: {
                top: 0;
                // bottom: 100px;
            };
            [class*=owl-] {
                font-size: 25px;
                margin: 0 5px;
                padding: 0;
                width: 45px;
                height: 45px;
                background: #f5f5f5;
                position: relative;
                border-radius: 50%;
                color: var(--optionalColor);
                text-align: center;
                transition: var(--transition);

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &.owl-prev {
                    margin-left: 0;
                }
                &.owl-next {
                    margin-right: 0;
                }
                &:hover {
                    color: var(--whiteColor);
                    background-color: var(--mainColor);
                }
            }
        }
    }
}
.testimonials-slides {
    .owl-theme {
        .owl-nav {
            [class*=owl-] {
                margin: 0;
                position: absolute;
                left: 15px;
                top: 50%;
                transform: translateY(-50%);
                width: 40px;
                height: 40px;
                background-color: var(--whiteColor);
                color: var(--blackColor);
                border-radius: 50%;
                font-size: 25px;
                padding: 0;
                text-align: center;
                transition: var(--transition);

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &.owl-next {
                    left: auto;
                    right: 15px;
                }
                &:hover {
                    background-color: var(--blackColor);
                    color: var(--whiteColor);
                }
            }
        }
    }
}
.products-slides {
    .owl-theme {
        .owl-nav {
            &.disabled+.owl-dots {
                margin-top: 63px;
            }
        }
        .owl-dots {
            .owl-dot {
                span {
                    width: 17px;
                    height: 17px;
                    border-radius: 50%;
                    background-color: transparent;
                    border: 1px solid #666666;
                    transition: var(--transition);
                    margin: 0 4px;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        background-color: #666666;
                        border-radius: 50%;
                        margin: 4px;
                        transition: var(--transition);
                    }
                }
                &:hover, &.active {
                    span {
                        border-color: var(--mainColor);

                        &::before {
                            background-color: var(--mainColor);
                        }
                    }
                }
            }
        }
    }
}
.products-details-image-slides {
    .owl-theme {
        margin-bottom: 35px;
        
        .owl-nav {
            margin-top: 0;
            opacity: 0;
            visibility: hidden;
            transition: var(--transition);

            [class*=owl-] {
                margin: 0;
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: 40px;
                height: 40px;
                background-color: var(--whiteColor);
                color: var(--blackColor);
                border-radius: 50%;
                font-size: 25px;
                padding: 0;
                text-align: center;
                transition: var(--transition);

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &.owl-next {
                    left: auto;
                    right: 20px;
                }
                &:hover {
                    background-color: var(--blackColor);
                    color: var(--whiteColor);
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

/*extra-css*/
.page-title-area {
    text-align: center;
    // margin-top: 13px;
    background: {
        // color: #f9f9f9;
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    padding: {
        top: 289px;
        bottom: 70px;
        left: 15px;
        right: 15px;
    };
    h1 {
        font-size: 36px;
        margin-bottom: 15px;
    }
    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            display: inline-block;
            color: var(--mainColor);
            font-weight: 500;
            position: relative;
            margin: {
                left: 11px;
                right: 11px;
            };
            a {
                display: block;
                color: var(--optionalColor);
            }
            &::before {
                content: "\e9b2";
                position: absolute;
                left: -20px;
                top: 1.3px;
                font-family: 'boxicons';
                color: var(--optionalColor);
            }
            &:first-child {
                &::before {
                    display: none;
                }
            }
        }
    }
}
.pagination-area {
    margin-top: 35px;

    .ngx-pagination {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            color: var(--blackColor);
            margin: {
                left: 5px;
                right: 5px;
            };
            font: {
                weight: 600;
                size: var(--fontSize);
            };
            &.disabled {
                color: var(--blackColor);
                background-color: transparent;
                border: 2px solid #f5f3f3;
                padding: 6px 15px;
            }
            a {
                padding: 6px 15px;
                color: var(--blackColor);
                transition: var(--transition);
                border: 2px solid #f5f3f3;
                background-color: transparent;
                
                &:hover, &.current {
                    background-color: transparent;
                    color: var(--blackColor);
                    border-color: var(--mainColor);
                }
            }
            &.current {
                background-color: transparent;
                color: var(--blackColor);
                border: 2px solid var(--mainColor);
                padding: 6px 15px;
            }
            &.pagination-previous {
                &::before {
                    position: relative;
                    top: -1px;
                }
                a {
                    &::before {
                        position: relative;
                        top: -1px;
                    }
                }
            }
            &.pagination-next {
                &::after {
                    position: relative;
                    top: -1px;
                }
                a {
                    &::after {
                        position: relative;
                        top: -1px;
                    }
                }
            }
        }
    }
}
.widget-area {
    padding-left: 15px;

    .widget {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
        .widget-title {
            font-size: 20px;
            margin-bottom: 25px;
            text-transform: uppercase;
        }
    }
    .widget_search {
        form {
            position: relative;

            label {
                display: block;
                margin-bottom: 0;
            }
            .screen-reader-text {
                display: none;
            }
            .search-field {
                height: 50px;
                display: block;
                width: 100%;
                border: none;
                border-radius: 5px;
                padding: 2px 0 0 15px;
                color: var(--blackColor);
                background-color: #f2f4f5;
                transition: var(--transition);
                font: {
                    size: var(--fontSize);
                    weight: 500;
                };
                &::placeholder {
                    transition: var(--transition);
                    color: var(--optionalColor);
                }
                &:focus {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            button {
                top: 5px;
                right: 5px;
                padding: 0;
                width: 40px;
                border: none;
                height: 40px;
                font-size: 20px;
                position: absolute;
                color: var(--mainColor);
                transition: var(--transition);
                background-color: var(--whiteColor);

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:hover {
                    border-radius: 5px;
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
        }
    }
    .widget_fiwan_posts_thumb {
        position: relative;
        overflow: hidden;
        
        .item {
            margin-bottom: 20px;
            position: relative;
            padding-left: 95px;

            .thumb {
                top: 0;
                left: 0;
                width: 80px;
                float: unset;
                height: 100%;
                display: block;
                position: absolute;

                .fullimage {
                    width: 80px;
                    height: 100%;
                    display: inline-block;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    };
                    &.bg1 {
                        background-image: url(assets/img/dog.png);
                    }
                    &.bg2 {
                        background-image: url(assets/img/blog/blog2.jpg);
                    }
                    &.bg3 {
                        background-image: url(assets/img/blog/blog3.jpg);
                    }
                    &.bg4 {
                        background-image: url(assets/img/blog/blog4.jpg);
                    }
                }
                &::before, &::after {
                    content: '';
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    opacity: 0;
                    position: absolute;
                    background-color: var(--whiteColor);
                    transform: translate(-50%, -50%);
                    transition: all .8s cubic-bezier(.2, 1, .22, 1);
                }
                &::before {
                    width: 40px;
                    height: 1px;
                    left: 100%;
                }
                &::after {
                    height: 40px;
                    width: 1px;
                    top: 0;
                }
            }
            .info {
                .title {
                    margin-bottom: 8px;
                    line-height: 1.4;
                    font: {
                        size: 16px;
                        weight: 600;
                    };
                    a {
                        display: inline-block;
                    }
                }
                .meta {
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style-type: none;

                    li {
                        display: inline-block;
                        margin-right: 15px;
                        color: var(--optionalColor);
                        position: relative;
                        padding-left: 20px;

                        i {
                            position: absolute;
                            left: 0;
                            top: 4px;
                        }
                        a {
                            display: inline-block;
                            color: var(--optionalColor);

                            &:hover {
                                color: var(--mainColor);
                            }
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
            &:hover {
                .thumb {
                    &::before, &::after {
                        opacity: 1;
                        top: 50%;
                        left: 50%;
                    }
                }
            }
        }
    }
    .widget_socials_link {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                margin-bottom: 15px;

                a {
                    display: block;
                    background-color: #f5f5f5;
                    border-radius: 0 0 15px 15px;
                    border-bottom: 3px solid #d6d6d6;
                    position: relative;
                    color: var(--blackColor);
                    padding: {
                        top: 15px;
                        bottom: 12px;
                        right: 15px;
                        left: 90px;
                    };
                    font: {
                        size: var(--fontSize);
                        weight: 600;
                    };
                    i {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 70px;
                        background-color: var(--mainColor);
                        color: var(--whiteColor);
                        border-radius: 0 0 0 15px;
                        font-size: 22px;
                        text-align: center;
                        transition: var(--transition);
                        
                        &::before {
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        &.bxl-facebook {
                            background-color: #485fb3;
                        }
                        &.bxl-twitter {
                            background-color: #00afe1;
                        }
                        &.bxl-linkedin {
                            background-color: #0e76a8;
                        }
                    }
                    &:hover {
                        border-color: var(--mainColor);
                        border-radius: 0;

                        i {
                            border-radius: 0;
                        }
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .widget_buy_now {
        position: relative;
        text-align: center;
        border-radius: 0 0 15px 15px;
        border-bottom: 3px solid #d6d6d6;

        img {
            border-radius: 0 0 15px 15px;
        }
        .content {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: var(--whiteColor);
            padding: 30px 25px;
            max-width: 300px;
            margin: {
                left: auto;
                right: auto;
            };
            img {
                border-radius: 0;
            }
            p {
                line-height: 1.6;
                margin: {
                    top: 15px;
                    bottom: 15px;
                };
            }
            .buy-now-btn {
                border: none;
                border-radius: 5px;
                display: inline-block;
                padding: 10px 35px;
                color: var(--whiteColor);
                transition: var(--transition);
                background-color: var(--blackColor);
                font: {
                    size: 16px;
                    weight: 500;
                };
                &:hover {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
            &::before {
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: -10px;
                z-index: -1;
                content: '';
                position: absolute;
                border: 1px solid var(--whiteColor);
            }
        }
    }
    .tagcloud {
        margin-top: -8px;

        a {
            display: inline-block;
            background: #f5f5f5;
            color: var(--optionalColor);
            padding: 7px 15px 6px;
            border: none;
            border-radius: 3px;
            font: {
                weight: 600;
                size: 15.5px !important;
            };
            margin: {
                top: 8px;
                right: 4px;
            };
            &:hover {
                color: var(--whiteColor);
                background-color: var(--mainColor);
            }
        }
    }
    .widget_price_filter {
        .collection_filter_by_price {
            .irs-handle {
                cursor: pointer;
            }
            .irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
                background-color: var(--mainColor);
                padding: 1px 5px 1px 11.5px;
    
                &::before {
                    border-top-color: var(--mainColor);
                }
                &::after {
                    content: '$';
                    position: absolute;
                    left: 5px;
                    top: 0.5px;
                }
            }
            .irs--flat .irs-bar {
                background-color: var(--mainColor);
            }
            .irs--flat .irs-handle>i:first-child {
                background-color: var(--mainColor);
            }
            .irs--flat .irs-min, .irs--flat .irs-max {
                padding: 1px 3px 1px 9.5px;
    
                &::before {
                    content: '$';
                    position: absolute;
                    left: 3px;
                    top: 0.5px;
                }
            }
        }
    }
    .widget_categories {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                overflow: hidden;
                margin-bottom: 12px;
                padding-bottom: 12px;
                border-bottom: 1px dashed #eeeeee;
                font: {
                    size: 15.5px;
                    weight: 600;
                };
                a {
                    display: inline-block;
                }
                .post_count {
                    float: right;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .widget_top_rated_products {
        .item {
            margin-bottom: 15px;
            position: relative;
            padding-left: 95px;

            .thumb {
                top: 0;
                left: 0;
                width: 80px;
                float: unset;
                height: 100%;
                display: block;
                position: absolute;

                .fullimage {
                    border: 1px solid #eeeeee;
                    width: 80px;
                    height: 100%;
                    display: inline-block;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    };
                    &.bg1 {
                        background-image: url(assets/img/products/products4.jpg);
                    }
                    &.bg2 {
                        background-image: url(assets/img/products/products5.jpg);
                    }
                    &.bg3 {
                        background-image: url(assets/img/products/products6.jpg);
                    }
                    &.bg4 {
                        background-image: url(assets/img/products/products7.jpg);
                    }
                }
            }
            .info {
                padding: {
                    top: 5px;
                    bottom: 5px;
                };
                .title {
                    margin-bottom: 10px;
                    line-height: 1.4;
                    font: {
                        size: 15.5px;
                        weight: 600;
                    };
                    a {
                        display: inline-block;
                    }
                }
                .star-rating {
                    font-size: 15px;

                    i {
                        color: #f49f0b;
                    }
                }
                .price {
                    margin-top: 5px;
                    font-weight: 600;
                    display: block;
                    color: var(--optionalColor);
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .widget_colors {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                display: block;
                margin-bottom: 12px;
                border-bottom: 1px dashed #eeeeee;
                padding-bottom: 12px;

                a {
                    display: flex;
                    align-items: center;

                    span {
                        display: block;
                        font-weight: 600;
                        color: var(--optionalColor);

                        &:nth-child(1) {
                            width: 17px;
                            height: 17px;
                            font-size: 0;
                            margin-right: 8px;
                            letter-spacing: 0;
                            border-radius: 50%;
                            transition: var(--transition);

                            &.gray {
                                background-color: gray;
                            }
                            &.red {
                                background-color: red;
                            }
                            &.green {
                                background-color: green;
                            }
                            &.black {
                                background-color: black;
                            }
                            &.brown {
                                background-color: brown
                            }
                        }
                    }
                    &:hover {
                        span {
                            &:nth-child(1) {
                                transform: scale(1.2);
                            }
                        }
                    }
                }
            }
        }
    }
    &.left-widget-area {
        padding: {
            left: 0;
            right: 15px;
        };
    }
}
.billing-details {
    .form-group {
        .ngx-dropdown-container {
            .ngx-dropdown-button {
                border: 1px solid #eeeeee !important;
                margin-bottom: 0 !important;
                overflow: unset !important;
                min-height: auto !important;
                border-radius: 5px !important;
                height: 45px !important;
                line-height: 41px !important;
                display: block !important;
                color: var(--blackColor) !important;
                transition: var(--transition) !important;
                background: #f8f8f8 !important;
                font: {
                    size: var(--fontSize) !important;
                    weight: 600 !important;
                };
                padding: {
                    left: 12px !important;
                    right: 0 !important;
                    bottom: 0 !important;
                    top: 0 !important;
                };
                .nsdicon-angle-down {
                    right: 12px !important;

                    &::before {
                        height: 8px !important;
                        width: 8px !important;
                        top: 3px !important;
                        border-color: var(--mainColor) !important;
                    }
                }
                &:hover {
                    border-color: var(--mainColor) !important;
                }
            }
            .ngx-dropdown-list-container {
                border: none !important;
                width: 100% !important;
                margin-bottom: 0 !important;
                margin-top: 0 !important;
                border-radius: 0 !important;
                padding-top: 0 !important;
                padding-left: 15px !important;
                padding-bottom: 15px !important;
                background-color: var(--whiteColor) !important;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2) !important;

                .search-container {
                    input {
                        border-color: #eeeeee !important;
                    }
                }
                ul {
                    margin-top: 15px !important;
                    margin-bottom: 5px !important;
                    padding-left: 0 !important;
                    text-align: left !important;
                    
                    li {
                        position: relative !important;
                        color: var(--blackColor) !important;
                        transition: var(--transition) !important;
                        text-align: left;
                        padding: {
                            left: 0 !important;
                            right: 0 !important;
                            top: 15px !important;
                            bottom: 0 !important;
                        };
                        &:first-child {
                            padding-top: 0 !important;
                        }
                        font: {
                            size: var(--fontSize) !important;
                            weight: 500 !important;
                        };
                        &:hover {
                            color: var(--mainColor) !important;
                        }
                    }
                    &.selected-items {
                        li {
                            background-color: transparent !important;
                            color: var(--mainColor) !important;
                            margin-bottom: 2px !important;
                        }
                    }
                }
            }
        }
    }
}
.fiwan-grid-sorting {
    .ordering {
        .ngx-dropdown-container {
            width: 215px !important;
            display: inline-block !important;

            .ngx-dropdown-button {
                border: 1px solid #eeeeee !important;
                margin-bottom: 0 !important;
                overflow: unset !important;
                min-height: auto !important;
                border-radius: 5px !important;
                height: 45px !important;
                line-height: 35px !important;
                display: block !important;
                color: var(--blackColor) !important;
                transition: var(--transition) !important;
                background: #f8f8f8 !important;
                font: {
                    size: var(--fontSize) !important;
                    weight: 600 !important;
                };
                padding: {
                    left: 12px !important;
                    right: 0 !important;
                    bottom: 0 !important;
                    top: 0 !important;
                };
                .nsdicon-angle-down {
                    right: 12px !important;

                    &::before {
                        height: 8px !important;
                        width: 8px !important;
                        top: 3px !important;
                        border-color: var(--mainColor) !important;
                    }
                }
                &:hover {
                    border-color: var(--mainColor) !important;
                }
            }
            .ngx-dropdown-list-container {
                border: none !important;
                width: 100% !important;
                margin-bottom: 0 !important;
                margin-top: 0 !important;
                border-radius: 0 !important;
                padding-top: 0 !important;
                padding-left: 15px !important;
                padding-bottom: 15px !important;
                background-color: var(--whiteColor) !important;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2) !important;

                .search-container {
                    input {
                        border-color: #eeeeee !important;
                    }
                }
                ul {
                    margin-top: 15px !important;
                    margin-bottom: 5px !important;
                    padding-left: 0 !important;
                    text-align: left !important;
                    
                    li {
                        position: relative !important;
                        color: var(--blackColor) !important;
                        transition: var(--transition) !important;
                        text-align: left;
                        padding: {
                            left: 0 !important;
                            right: 0 !important;
                            top: 15px !important;
                            bottom: 0 !important;
                        };
                        &:first-child {
                            padding-top: 0 !important;
                        }
                        font: {
                            size: var(--fontSize) !important;
                            weight: 500 !important;
                        };
                        &:hover {
                            color: var(--mainColor) !important;
                        }
                    }
                    &.selected-items {
                        li {
                            background-color: transparent !important;
                            color: var(--mainColor) !important;
                            margin-bottom: 2px !important;
                        }
                    }
                }
            }
        }
    }
}
@keyframes movebounce {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0);
    }
}

/*================================================
Modal CSS
=================================================*/
.modal {
    &.right {
        .modal-dialog {
            position: fixed;
            margin: auto;
            width: 420px;
            height: 100%;
            transform: translate3d(0%, 0, 0);
        }
        .modal-content {
            height: 100%;
            overflow-y: auto;
            background-color: var(--whiteColor);
            border: none;
            border-radius: 0;

            button.close {
                float: unset;
                position: absolute;
                right: 20px;
                top: 15px;
                color: var(--blackColor);
                z-index: 2;
                background-color: transparent;
                opacity: 1;
                border: none;
                text-shadow: unset;
                box-shadow: unset;
                font-size: 40px;
                padding: 0;
                transition: var(--transition);

                &:hover {
                    color: red;
                }
            }
        }
        &.fade {
            .modal-dialog {
                right: -320px;
                transition: opacity 0.3s linear, right 0.3s ease-out;
            }
            &.show {
                .modal-dialog {
                    right: 0;
                }
            }
        }
    }
}
.sidebarModal {
    &.modal {
        .modal-body {
            text-align: center;
            padding: 80px 30px;

            .instagram-list {
                margin-top: 60px;
    
                .row {
                    margin: {
                        left: -5px;
                        right: -5px;
                    };
                    .col-lg-4 {
                        padding: {
                            left: 5px;
                            right: 5px;
                        };
                    }
                }
                .box {
                    position: relative;
                    z-index: 1;
                    overflow: hidden;
                    margin-top: 10px;
    
                    .link-btn {
                        display: block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        z-index: 3;
                    }
                    i {
                        position: absolute;
                        left: 0;
                        right: 0;
                        color: var(--whiteColor);
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 30px;
                        transition: var(--transition);
                        opacity: 0;
                        visibility: hidden;
                        z-index: 2;
                    }
                    img {
                        transition: var(--transition);
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        z-index: 1;
                        background-color: var(--mainColor);
                        transition: var(--transition);
                        opacity: 0;
                        visibility: hidden;
                    }
                    &:hover {
                        &::before {
                            opacity: 0.8;
                            visibility: visible;
                        }
                        img {
                            transform: scale(1.3);
                        }
                        i {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
            .sidebar-contact-info {
                margin-top: 60px;

                h2 {
                    margin-bottom: 0;
                    font: {
                        size: 30px;
                        weight: 600;
                    };
                    span {
                        display: block;
                        font-size: var(--fontSize);
                        margin: {
                            top: 10px;
                            bottom: 8px;
                        };
                    }
                }
            }
            .social-list {
                list-style-type: none;
                padding-left: 0;
                margin: {
                    bottom: 0;
                    top: 15px;
                };
                li {
                    display: inline-block;
                    margin-right: 8px;

                    span {
                        display: block;
                        color: var(--mainColor);
                        font: {
                            weight: 600;
                        };
                    }
                    a {
                        position: relative;
                        top: 3px;
                        font-size: 20px;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
.productsFilterModal {
    &.modal {
        .modal-body {
            padding: 0;
        }
        .modal-content {
            padding: 30px;

            button {
                &.close {
                    top: 0;
                    right: 0;
                    font-size: 17px;
                    margin-bottom: 35px;
                    text-align: left;
                    position: relative;
                    padding: 0;
                    padding-left: 25px;
                    background-color: transparent;
                    border: none;

                    i {
                        position: absolute;
                        font-size: 20px;
                        left: 0;
                        top: 0;
                    }
                }
            }
        }
    }
}
.productsQuickView {
    padding-right: 0 !important;

    .modal-dialog {
        max-width: 900px;
        margin: 0 auto;
    }
    .modal-content {
        border: none;
        padding: 40px;
        background-color: #f9f9f9;
        border-radius: 0;

        button {
            &.close {
                top: 5px;
                right: 5px;
                z-index: 1;
                outline: 0;
                padding: 0;
                margin: 0;
                opacity: 1;
                width: 35px;
                height: 35px;
                font-size: 25px;
                line-height: 41px;
                position: absolute;
                text-shadow: unset;
                border-radius: 50%;
                color: var(--blackColor);
                transition: var(--transition);
                border: none;
                padding: 0;
                background-color: var(--whiteColor);

                &:hover {
                    background-color: red;
                    color: var(--whiteColor);
                }
            }
        }
        .products-content {
            h3 {
                margin-bottom: 0;
                font-size: 22px;
            }
            .price {
                transition: var(--transition);
                margin: {
                    top: 12px;
                    bottom: 15px;
                };
                font: {
                    size: var(--fontSize);
                    weight: 600;
                };
                .old-price {
                    text-decoration: line-through;
                    color: #999999;
                    font-weight: 500;
                }
            }
            .products-review {
                margin-bottom: 15px;

                .rating {
                    display: inline-block;
                    padding-right: 5px;

                    i {
                        color: #f49f0b;
                        display: inline-block;
                    }
                }
                .rating-count {
                    display: inline-block;
                    color: var(--blackColor);
                    border-bottom: 1px solid var(--blackColor);
                    line-height: initial;
                    position: relative;
                    top: -1px;

                    &:hover {
                        color: var(--mainColor);
                        border-color: var(--mainColor);
                    }
                }
            }
            .products-info {
                list-style-type: none;
                padding-left: 0;
                margin: {
                    top: 15px;
                    bottom: 0;
                };
                li {
                    color: var(--optionalColor);
                    margin-bottom: 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    span {
                        color: var(--optionalColor);
                    }
                    a {
                        display: inline-block;
                        font-weight: 600;
                        color: var(--blackColor);

                        &:hover {
                            color: var(--mainColor);
                        }
                    }
                }
            }
            .products-add-to-cart {
                margin-top: 15px;

                .input-counter {
                    max-width: 110px;
                    min-width: 110px;
                    margin-right: 10px;
                    text-align: center;
                    display: inline-block;
                    position: relative;

                    span {
                        top: 0;
                        cursor: pointer;
                        color: #d0d0d0;
                        width: 40px;
                        height: 100%;
                        line-height: 55px;
                        font-size: 18px;
                        position: absolute;
                        background-color: transparent;
                        transition: var(--transition);

                        &.minus-btn {
                            left: 0;
                        }
                        &.plus-btn {
                            right: 0;
                        }
                        &:hover {
                            color: var(--mainColor);
                        }
                    }
                    input {
                        height: 50px;
                        outline: 0;
                        display: block;
                        border: none;
                        text-align: center;
                        width: 100%;
                        color: var(--blackColor);
                        background-color: var(--whiteColor);
                        font: {
                            size: 17px;
                            weight: 600;
                        }
                        &::placeholder {
                            color: var(--blackColor);
                        }
                    }
                    .default-btn {
                        i {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {

    body {
        font-size: 14px;
    }
    p {
        font-size: 14px;
    }
    .ptb-100 {
        padding: {
            // top: 60px;
            // bottom: 60px;
        };
    }
    .pt-100 {
        padding-top: 60px;
    }
    .pb-100 {
        padding-bottom: 60px;
    }
    .ptb-70 {
        padding: {
            top: 30px;
            bottom: 30px;
        };
    }
    .pt-70 {
        padding-top: 30px;
    }
    .pb-70 {
        padding-bottom: 30px;
    }
    .container {
        max-width: 100%;
    }
    .section-title {
        max-width: 100%;
        margin: {
            left: 0;
            right: 0;
            top: -7px;
            bottom: 40px;
        };
        h2 {
            font-size: 24px;
            // margin-top: -89px;
        }
    }
    .default-btn {
        font-size: 14px;
        padding: 11px 20px 10px 45px;

        i {
            left: 20px;
            font-size: 15px;
        }
    }
    .form-control {
        font-size: 14px;
        padding-left: 10px;
    }

    .fiwan-responsive-nav {
        .fiwan-responsive-menu {
            &.mean-container {
                .mean-nav {
                    // margin-top: 39px;

                    ul {
                        font-size: 15px;

                        li {
                            a {
                                &.active {
                                    color: var(--mainColor);
                                }
                            }
                            li {
                                a {
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }
                .navbar-nav {
                    overflow-y: scroll;
                    // height: 357px;
                    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, .1);
                }
            }
        }
        .mean-container {
            a {
                &.meanmenu-reveal {
                    color: var(--blackColor);

                    span {
                        background: var(--blackColor);
                    }
                }
            }
        }
    }

    .home-slides {
        .owl-theme {
            .owl-nav {
                left: 0;
                bottom: 0;
                bottom: 0;
                margin-top: 20px;
                position: relative;

                [class*=owl-] {
                    width: 38px;
                    height: 38px;
                    font-size: 20px;
                }
            }
        }
    }
    .home-slides-two {
        .owl-theme {
            .owl-nav {
                margin-bottom: 60px;

                [class*=owl-] {
                    width: 38px;
                    height: 38px;
                    font-size: 20px;
                }
            }
        }
    }
    .testimonials-slides {
        .owl-theme {
            .owl-nav {
                [class*=owl-] {
                    top: auto;
                    bottom: 8px;
                    transform: unset;
                    width: 35px;
                    height: 35px;
                    font-size: 20px;
                    left: 0;

                    &.owl-next {
                        left: auto;
                        right: 0;
                    }
                }
            }
        }
    }
    .products-slides {
        .owl-theme {
            .owl-nav {
                &.disabled+.owl-dots {
                    margin-top: 0;
                }
            }
            .owl-dots {
                .owl-dot {
                    span {
                        width: 15px;
                        height: 15px;
                        margin: 0 3px;
                    }
                }
            }
        }
    }
    .products-details-image-slides {
        .owl-theme {
            .owl-nav {
               opacity: 1;
               visibility: visible;

               [class*=owl-] {
                    left: 10px;
                    width: 35px;
                    height: 35px;
                    font-size: 20px;

                    &.owl-next {
                        left: auto;
                        right: 10px;
                    }
                }
            }
        }
    }

    .page-title-area {
        // margin-top: 55px;
        padding: {
            top: 27px;
            // bottom: 88px;
           
        };
        h1 {
            font-size: 24px;
            margin-bottom: 10px;
        }
        ul {
            li {
                &::before {
                    top: 1.4px;
                }
            }
        }
    }

    .billing-details {
        .form-group {
            .ngx-dropdown-container {
                .ngx-dropdown-button {
                    font-size: 14px !important;
                }
            }
        }
    }

    .fiwan-grid-sorting {
        .ordering {
            .ngx-dropdown-container {
                .ngx-dropdown-button {
                    font-size: 14px !important;
                }
            }
        }
    }

    .pagination-area {
        margin-top: 5px;

        .ngx-pagination {
            li {
                font-size: 14px;
                margin: {
                    left: 3px;
                    right: 3px;
                };
            }
        }
    }

    .widget-area {
        padding-left: 0;
        margin-top: 40px;

        .widget {
            margin-bottom: 30px;

            .widget-title {
                font-size: 17px;
                margin-bottom: 25px;
            }
        }
        .widget_fiwan_posts_thumb {
            .item {
                .info {
                    .title {
                        font-size: 15px;
                    }
                    .meta {
                        li {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
        .widget_socials_link {
            ul {
                li {
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
        .widget_buy_now {
            .content {
                padding: 15px;
                max-width: 225px;

                &::before {
                    margin: -10px;
                }
            }
        }
        .widget_categories {
            ul {
                li {
                    font-size: 14.5px;
                }
            }
        }
        .tagcloud {
            a {
                font-size: 13.5px !important;
            }
        }
        &.left-widget-area {
            padding-right: 0;
            margin: {
                top: 0;
                bottom: 40px;
            };
        }
        .widget_top_rated_products {
            .item {
                .info {
                    .title {
                        font-size: 14.5px;
                    }
                }
            }
        }
    }

    .modal {
        &.right {
            .modal-dialog {
                width: 100%;
            }
            .modal-content {
                button.close {
                    right: 20px;
                    top: 20px;
                    font-size: 25px;
                }
            }
        }
    }
    .sidebarModal {
        &.modal {
            .modal-body {
                text-align: left;
                padding: 20px;

                .instagram-list {
                    margin-top: 30px;
                }
                .sidebar-contact-info {
                    margin-top: 30px;

                    h2 {
                        font-size: 20px;

                        span {
                            font-size: 13px;
                        }
                    }
                }
                .social-list {
                    li {
                        a {
                            position: relative;
                            top: 3px;
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }
    .productsQuickView {
        .modal-dialog {
            max-width: 300px;
            margin: 0 auto;
        }
        .modal-content {
            padding: 15px;

            .products-content {
                margin-top: 25px;

                h3 {
                    font-size: 18px;
                }
                .price {
                    font-size: 14px;
                }
                .products-info {
                    li {
                        font-size: 14px;
                    }
                }
                .products-color-switch {
                    h4 {
                        font-size: 14px;
                    }
                }
                .products-size-wrapper {
                    h4 {
                        font-size: 14px;
                    }
                }
                .products-add-to-cart {
                    .input-counter {
                        input {
                            height: 43px;
                            font-size: 16px;
                        }
                        span {
                            line-height: 49px;
                        }
                    }
                }
            }
        }
    }
    .productsFilterModal {
        &.modal {
            .modal-content {
                padding: 20px;

                button {
                    &.close {
                        margin-bottom: 25px;
                        right: 0;
                        top: 0;
                        font-size: 17px;
                    }
                }
                .widget-area {
                    margin: {
                        top: 0 !important;
                        bottom: 0 !important;
                    };
                }
            }
        }
    }

}

/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {

    .container {
        max-width: 540px;
    }

    .productsQuickView {
        .modal-dialog {
            max-width: 500px;
        }
        .modal-content {
            padding: 30px;
        }
    }

}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {

    body {
        font-size: 15px;
    }
    p {
        font-size: 15px;
    }
    .ptb-100 {
        padding: {
            top: 80px;
            bottom: 80px;
        };
    }
    .pt-100 {
        padding-top: 80px;
    }
    .pb-100 {
        padding-bottom: 80px;
    }
    .ptb-70 {
        padding: {
            top: 50px;
            bottom: 50px;
        };
    }
    .pt-70 {
        padding-top: 50px;
    }
    .pb-70 {
        padding-bottom: 50px;
    }
    .container {
        max-width: 720px;
    }
    .section-title {
        max-width: 100%;
        margin: {
            left: 0;
            right: 0;
            bottom: 50px;
        };
        h2 {
            font-size: 30px;
           
        }
        p {
            max-width: 600px;
            margin: {
                left: auto;
                right: auto;
            };
        }
    }
    .default-btn {
        font-size: 15px;
        padding: 11px 25px 11px 50px;

        i {
            left: 22px;
            font-size: 18px;
            margin-top: -1px;
        }
    }

    .fiwan-responsive-nav {
        .fiwan-responsive-menu {
            &.mean-container {
                .mean-nav {
                    margin-top: 39px;

                    ul {
                        font-size: 15px;

                        li {
                            a {
                                &.active {
                                    color: var(--mainColor);
                                }
                            }
                            li {
                                a {
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }
                .navbar-nav {
                    overflow-y: scroll;
                    // height: 357px;
                    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, .1);
                }
            }
        }
        .mean-container {
            a {
                &.meanmenu-reveal {
                    color: var(--blackColor);

                    span {
                        background: var(--blackColor);
                    }
                }
            }
        }
    }

    .home-slides {
        .owl-theme {
            .owl-nav {
                left: 0;
                position: relative;
                margin-top: 30px;
            }
        }
    }
    .home-slides-two {
        .owl-theme {
            .owl-nav {
                margin-bottom: 80px;
            }
        }
    }
    .products-slides {
        .owl-theme {
            .owl-nav {
                &.disabled+.owl-dots {
                    margin-top: 0;
                }
            }
        }
    }
    .testimonials-slides {
        .owl-theme {
            .owl-nav {
                [class*=owl-] {
                    top: auto;
                    bottom: 9px;
                    transform: unset;
                    left: 0;

                    &.owl-next {
                        left: auto;
                        right: 0;
                    }
                }
            }
        }
    }
    .products-details-image-slides {
        .owl-theme {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .page-title-area {
        margin-top: 55px;
        
        h1 {
            font-size: 30px;
        }
    }

    .widget-area {
        padding-left: 0;
        margin-top: 40px;

        .widget {
            margin-bottom: 30px;

            .widget-title {
                font-size: 19px;
                margin-bottom: 25px;
            }
        }
        &.left-widget-area {
            padding-right: 0;
            margin: {
                top: 0;
                bottom: 40px;
            };
        }
    }

    .productsQuickView {
        .modal-dialog {
            max-width: 740px;
        }
        .modal-content {
            padding: 20px;
        }
    }
    .productsFilterModal {
        &.modal {
            .modal-content {
                .widget-area {
                    margin-bottom: 0;
                }
            }
        }
    }

}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .section-title {
        h2 {
            font-size: 32px;
        }
    }
    .container {
        max-width: 960px;
    }
    .default-btn {
        font-size: 15px;
    }

    .fiwan-responsive-nav {
        .fiwan-responsive-menu {
            &.mean-container {
                .mean-nav {
                    margin-top: 46px;

                    ul {
                        font-size: 15px;

                        li {
                            a {
                                &.active {
                                    color: var(--mainColor);
                                }
                            }
                            li {
                                a {
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }
                .navbar-nav {
                    overflow-y: scroll;
                    // height: 357px;
                    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, .1);
                }
            }
        }
        .mean-container {
            a {
                &.meanmenu-reveal {
                    color: var(--blackColor);

                    span {
                        background: var(--blackColor);
                    }
                }
            }
        }
    }

    .home-slides {
        .owl-theme {
            .owl-nav {
                left: 0;
            }
        }
    }

    .widget-area {
        padding-left: 0;

        .widget_buy_now {
            .content {
                max-width: 220px;
            }
        }
        &.left-widget-area {
            padding-right: 0;
        }
    }

    .page-title-area {
        margin-top: 65px;
    }

}

/* Min width 1200px to Max width 1355px */
@media only screen and (min-width: 1200px) and (max-width: 1355px) {

    .container {
        max-width: 1140px;
    }

    .home-slides {
        .owl-theme {
            .owl-nav {
                left: 50px;
            }
        }
    }

}

/* Min width 1550px */
@media only screen and (min-width: 1550px) {

    .home-slides {
        .owl-theme {
            .owl-nav {
                left: 100px;
            }
        }
    }

}
/* You can add global styles to this file, and also import other style files */
/*================================================
Default CSS
=================================================*/
:root {
    --fontFamily: 'Source Sans Pro', sans-serif;
    // --mainColor: #0ca59d;
    // --mainColor: #FBDA6F;
     --mainColor: #FBDA6F;
    --optionalColor: #666666;
    --whiteColor: #ffffff;
    --blackColor: #000000;
    --fontSize: 16px;
    --transition: .5s;
}
body {
    margin: 0;
    padding: 0;
    color: var(--blackColor);
    font: {
        size: var(--fontSize);
        family: var(--fontFamily);
    };
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    color: var(--blackColor);
}
a {
    color: var(--blackColor);
    transition: var(--transition);
    text-decoration: none;
    outline: 0 !important;

    &:hover {
        color: var(--mainColor);
        text-decoration: none;
    }
}
:focus {
    outline: 0 !important;
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
img {
    max-width: 90%;
    height: auto;
    // margin-top: -12px;
}
p {
    color: var(--optionalColor);
    font-size: var(--fontSize);
    margin-bottom: 15px;
    line-height: 1.8;

    &:last-child {
        margin-bottom: 0;
    }
}
.ptb-100 {
    padding: {
        // top: 100px;
        // bottom: 100px;
    };
}
.pt-100 {
    // padding-top: 100px;
    margin-top: 19px;
    // padding-top: 27px;
}
.pb-100 {
    padding-bottom: 100px;
}
.ptb-70 {
    padding: {
        top: 70px;
        bottom: 70px;
    };
}
.pt-70 {
    padding-top: 70px;
}
.pb-70 {
    // padding-bottom: 70px;
}
.container {
    max-width: 1230px;
  
}
.bg-f5f5f5 {
    // background-color: #f5f5f5;
}
.bg-e8fdff {
    background-color: #e8fdff;
}
.bg-fafafa {
    background-color: #fafafa;
}

/*section-title*/
.section-title {
    text-align: center;
    max-width: 720px;
    margin: {
        left: auto;
        right: auto;
        bottom: 60px;
        top: 34px;
    };
    .sub-title {
        display: block;
        font-weight: 600;
        margin-bottom: 12px;
    }
    h2 {
        font-size: 36px;
        margin-bottom: 12px;
    }
    p {
        max-width: 650px;
        margin: {
            left: auto;
            right: auto;
        };
    }
}

/*default-btn*/
.default-btn {
    border: none;
    position: relative;
    display: inline-block;
    // color: var(--whiteColor);
    color: var(--blackColor);
    box-shadow: unset !important;
    transition: var(--transition);
    background-color: var(--mainColor);
    padding: 13px 30px 12px 55px;
    font: {
        weight: 600;
        size: var(--fontSize);
    };
    i {
        top: 50%;
        left: 28px;
        font-size: 20px;
        position: absolute;
        margin-top: -1px;
        transform: translateY(-50%);
    }
    &:hover {
        // color: var(--whiteColor);
        color: #FBDA6F;
        background-color: var(--blackColor);
    }
}

/*form-control*/
.form-control {
    border-radius: 0;
    background-color: #f5f5f5 !important;
    box-shadow: unset !important;
    transition: var(--transition);
    border: none !important;
    height: 50px;
    padding-left: 15px;
    color: var(--blackColor);
    font: {
        size: var(--fontSize);
        weight: 500;
    };
    &::placeholder {
        color: #999999;
        transition: var(--transition);
    }
    &:focus {
        &::placeholder {
            color: transparent;
        }
    }
}
textarea.form-control {
    padding-top: 15px;
    height: auto;
}

/*owl-carousel-css*/
.home-slides {
    .owl-theme {
        .owl-nav {
            left: 80px;
            bottom: 0;
            margin-top: 0;
            position: absolute;

            [class*=owl-] {
                font-size: 25px;
                margin: 0 5px;
                padding: 0;
                width: 45px;
                height: 45px;
                background: #f5f5f5;
                position: relative;
                border-radius: 50%;
                color: var(--optionalColor);
                text-align: center;
                transition: var(--transition);

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &.owl-prev {
                    margin-left: 0;
                }
                &.owl-next {
                    margin-right: 0;
                }
                &:hover {
                    color: var(--whiteColor);
                    background-color: var(--mainColor);
                }
            }
        }
    }
}
.home-slides-two {
    .owl-theme {
        .owl-nav {
            margin: {
                top: 0;
                // bottom: 100px;
            };
            [class*=owl-] {
                font-size: 25px;
                margin: 0 5px;
                padding: 0;
                width: 45px;
                height: 45px;
                background: #f5f5f5;
                position: relative;
                border-radius: 50%;
                color: var(--optionalColor);
                text-align: center;
                transition: var(--transition);

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &.owl-prev {
                    margin-left: 0;
                }
                &.owl-next {
                    margin-right: 0;
                }
                &:hover {
                    color: var(--whiteColor);
                    background-color: var(--mainColor);
                }
            }
        }
    }
}
.testimonials-slides {
    .owl-theme {
        .owl-nav {
            [class*=owl-] {
                margin: 0;
                position: absolute;
                left: 15px;
                top: 50%;
                transform: translateY(-50%);
                width: 40px;
                height: 40px;
                background-color: var(--whiteColor);
                color: var(--blackColor);
                border-radius: 50%;
                font-size: 25px;
                padding: 0;
                text-align: center;
                transition: var(--transition);

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &.owl-next {
                    left: auto;
                    right: 15px;
                }
                &:hover {
                    background-color: var(--blackColor);
                    color: var(--whiteColor);
                }
            }
        }
    }
}
.products-slides {
    .owl-theme {
        .owl-nav {
            &.disabled+.owl-dots {
                margin-top: 63px;
            }
        }
        .owl-dots {
            .owl-dot {
                span {
                    width: 17px;
                    height: 17px;
                    border-radius: 50%;
                    background-color: transparent;
                    border: 1px solid #666666;
                    transition: var(--transition);
                    margin: 0 4px;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        background-color: #666666;
                        border-radius: 50%;
                        margin: 4px;
                        transition: var(--transition);
                    }
                }
                &:hover, &.active {
                    span {
                        border-color: var(--mainColor);

                        &::before {
                            background-color: var(--mainColor);
                        }
                    }
                }
            }
        }
    }
}
.products-details-image-slides {
    .owl-theme {
        margin-bottom: 35px;
        
        .owl-nav {
            margin-top: 0;
            opacity: 0;
            visibility: hidden;
            transition: var(--transition);

            [class*=owl-] {
                margin: 0;
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: 40px;
                height: 40px;
                background-color: var(--whiteColor);
                color: var(--blackColor);
                border-radius: 50%;
                font-size: 25px;
                padding: 0;
                text-align: center;
                transition: var(--transition);

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &.owl-next {
                    left: auto;
                    right: 20px;
                }
                &:hover {
                    background-color: var(--blackColor);
                    color: var(--whiteColor);
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

/*extra-css*/
.page-title-area {
    text-align: center;
    // margin-top: 13px;
    background: {
        // color: #f9f9f9;
        position: center center;
        size: cover;
        repeat: no-repeat;
    };
    padding: {
        top: 289px;
        bottom: 70px;
        left: 15px;
        right: 15px;
    };
    h1 {
        font-size: 36px;
        margin-bottom: 15px;
    }
    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            display: inline-block;
            color: var(--mainColor);
            font-weight: 500;
            position: relative;
            margin: {
                left: 11px;
                right: 11px;
            };
            a {
                display: block;
                color: var(--optionalColor);
            }
            &::before {
                content: "\e9b2";
                position: absolute;
                left: -20px;
                top: 1.3px;
                font-family: 'boxicons';
                color: var(--optionalColor);
            }
            &:first-child {
                &::before {
                    display: none;
                }
            }
        }
    }
}
.pagination-area {
    margin-top: 35px;

    .ngx-pagination {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            color: var(--blackColor);
            margin: {
                left: 5px;
                right: 5px;
            };
            font: {
                weight: 600;
                size: var(--fontSize);
            };
            &.disabled {
                color: var(--blackColor);
                background-color: transparent;
                border: 2px solid #f5f3f3;
                padding: 6px 15px;
            }
            a {
                padding: 6px 15px;
                color: var(--blackColor);
                transition: var(--transition);
                border: 2px solid #f5f3f3;
                background-color: transparent;
                
                &:hover, &.current {
                    background-color: transparent;
                    color: var(--blackColor);
                    border-color: var(--mainColor);
                }
            }
            &.current {
                background-color: transparent;
                color: var(--blackColor);
                border: 2px solid var(--mainColor);
                padding: 6px 15px;
            }
            &.pagination-previous {
                &::before {
                    position: relative;
                    top: -1px;
                }
                a {
                    &::before {
                        position: relative;
                        top: -1px;
                    }
                }
            }
            &.pagination-next {
                &::after {
                    position: relative;
                    top: -1px;
                }
                a {
                    &::after {
                        position: relative;
                        top: -1px;
                    }
                }
            }
        }
    }
}
.widget-area {
    padding-left: 15px;

    .widget {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
        .widget-title {
            font-size: 20px;
            margin-bottom: 25px;
            text-transform: uppercase;
        }
    }
    .widget_search {
        form {
            position: relative;

            label {
                display: block;
                margin-bottom: 0;
            }
            .screen-reader-text {
                display: none;
            }
            .search-field {
                height: 50px;
                display: block;
                width: 100%;
                border: none;
                border-radius: 5px;
                padding: 2px 0 0 15px;
                color: var(--blackColor);
                background-color: #f2f4f5;
                transition: var(--transition);
                font: {
                    size: var(--fontSize);
                    weight: 500;
                };
                &::placeholder {
                    transition: var(--transition);
                    color: var(--optionalColor);
                }
                &:focus {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            button {
                top: 5px;
                right: 5px;
                padding: 0;
                width: 40px;
                border: none;
                height: 40px;
                font-size: 20px;
                position: absolute;
                color: var(--mainColor);
                transition: var(--transition);
                background-color: var(--whiteColor);

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:hover {
                    border-radius: 5px;
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
        }
    }
    .widget_fiwan_posts_thumb {
        position: relative;
        overflow: hidden;
        
        .item {
            margin-bottom: 20px;
            position: relative;
            padding-left: 95px;

            .thumb {
                top: 0;
                left: 0;
                width: 80px;
                float: unset;
                height: 100%;
                display: block;
                position: absolute;

                .fullimage {
                    width: 80px;
                    height: 100%;
                    display: inline-block;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    };
                    &.bg1 {
                        background-image: url(assets/img/dog.png);
                    }
                    &.bg2 {
                        background-image: url(assets/img/blog/blog2.jpg);
                    }
                    &.bg3 {
                        background-image: url(assets/img/blog/blog3.jpg);
                    }
                    &.bg4 {
                        background-image: url(assets/img/blog/blog4.jpg);
                    }
                }
                &::before, &::after {
                    content: '';
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    opacity: 0;
                    position: absolute;
                    background-color: var(--whiteColor);
                    transform: translate(-50%, -50%);
                    transition: all .8s cubic-bezier(.2, 1, .22, 1);
                }
                &::before {
                    width: 40px;
                    height: 1px;
                    left: 100%;
                }
                &::after {
                    height: 40px;
                    width: 1px;
                    top: 0;
                }
            }
            .info {
                .title {
                    margin-bottom: 8px;
                    line-height: 1.4;
                    font: {
                        size: 16px;
                        weight: 600;
                    };
                    a {
                        display: inline-block;
                    }
                }
                .meta {
                    padding-left: 0;
                    margin-bottom: 0;
                    list-style-type: none;

                    li {
                        display: inline-block;
                        margin-right: 15px;
                        color: var(--optionalColor);
                        position: relative;
                        padding-left: 20px;

                        i {
                            position: absolute;
                            left: 0;
                            top: 4px;
                        }
                        a {
                            display: inline-block;
                            color: var(--optionalColor);

                            &:hover {
                                color: var(--mainColor);
                            }
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
            &:hover {
                .thumb {
                    &::before, &::after {
                        opacity: 1;
                        top: 50%;
                        left: 50%;
                    }
                }
            }
        }
    }
    .widget_socials_link {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                margin-bottom: 15px;

                a {
                    display: block;
                    background-color: #f5f5f5;
                    border-radius: 0 0 15px 15px;
                    border-bottom: 3px solid #d6d6d6;
                    position: relative;
                    color: var(--blackColor);
                    padding: {
                        top: 15px;
                        bottom: 12px;
                        right: 15px;
                        left: 90px;
                    };
                    font: {
                        size: var(--fontSize);
                        weight: 600;
                    };
                    i {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 70px;
                        background-color: var(--mainColor);
                        color: var(--whiteColor);
                        border-radius: 0 0 0 15px;
                        font-size: 22px;
                        text-align: center;
                        transition: var(--transition);
                        
                        &::before {
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        &.bxl-facebook {
                            background-color: #485fb3;
                        }
                        &.bxl-twitter {
                            background-color: #00afe1;
                        }
                        &.bxl-linkedin {
                            background-color: #0e76a8;
                        }
                    }
                    &:hover {
                        border-color: var(--mainColor);
                        border-radius: 0;

                        i {
                            border-radius: 0;
                        }
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .widget_buy_now {
        position: relative;
        text-align: center;
        border-radius: 0 0 15px 15px;
        border-bottom: 3px solid #d6d6d6;

        img {
            border-radius: 0 0 15px 15px;
        }
        .content {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-color: var(--whiteColor);
            padding: 30px 25px;
            max-width: 300px;
            margin: {
                left: auto;
                right: auto;
            };
            img {
                border-radius: 0;
            }
            p {
                line-height: 1.6;
                margin: {
                    top: 15px;
                    bottom: 15px;
                };
            }
            .buy-now-btn {
                border: none;
                border-radius: 5px;
                display: inline-block;
                padding: 10px 35px;
                color: var(--whiteColor);
                transition: var(--transition);
                background-color: var(--blackColor);
                font: {
                    size: 16px;
                    weight: 500;
                };
                &:hover {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
            &::before {
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: -10px;
                z-index: -1;
                content: '';
                position: absolute;
                border: 1px solid var(--whiteColor);
            }
        }
    }
    .tagcloud {
        margin-top: -8px;

        a {
            display: inline-block;
            background: #f5f5f5;
            color: var(--optionalColor);
            padding: 7px 15px 6px;
            border: none;
            border-radius: 3px;
            font: {
                weight: 600;
                size: 15.5px !important;
            };
            margin: {
                top: 8px;
                right: 4px;
            };
            &:hover {
                color: var(--whiteColor);
                background-color: var(--mainColor);
            }
        }
    }
    .widget_price_filter {
        .collection_filter_by_price {
            .irs-handle {
                cursor: pointer;
            }
            .irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
                background-color: var(--mainColor);
                padding: 1px 5px 1px 11.5px;
    
                &::before {
                    border-top-color: var(--mainColor);
                }
                &::after {
                    content: '$';
                    position: absolute;
                    left: 5px;
                    top: 0.5px;
                }
            }
            .irs--flat .irs-bar {
                background-color: var(--mainColor);
            }
            .irs--flat .irs-handle>i:first-child {
                background-color: var(--mainColor);
            }
            .irs--flat .irs-min, .irs--flat .irs-max {
                padding: 1px 3px 1px 9.5px;
    
                &::before {
                    content: '$';
                    position: absolute;
                    left: 3px;
                    top: 0.5px;
                }
            }
        }
    }
    .widget_categories {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                overflow: hidden;
                margin-bottom: 12px;
                padding-bottom: 12px;
                border-bottom: 1px dashed #eeeeee;
                font: {
                    size: 15.5px;
                    weight: 600;
                };
                a {
                    display: inline-block;
                }
                .post_count {
                    float: right;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .widget_top_rated_products {
        .item {
            margin-bottom: 15px;
            position: relative;
            padding-left: 95px;

            .thumb {
                top: 0;
                left: 0;
                width: 80px;
                float: unset;
                height: 100%;
                display: block;
                position: absolute;

                .fullimage {
                    border: 1px solid #eeeeee;
                    width: 80px;
                    height: 100%;
                    display: inline-block;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    };
                    &.bg1 {
                        background-image: url(assets/img/products/products4.jpg);
                    }
                    &.bg2 {
                        background-image: url(assets/img/products/products5.jpg);
                    }
                    &.bg3 {
                        background-image: url(assets/img/products/products6.jpg);
                    }
                    &.bg4 {
                        background-image: url(assets/img/products/products7.jpg);
                    }
                }
            }
            .info {
                padding: {
                    top: 5px;
                    bottom: 5px;
                };
                .title {
                    margin-bottom: 10px;
                    line-height: 1.4;
                    font: {
                        size: 15.5px;
                        weight: 600;
                    };
                    a {
                        display: inline-block;
                    }
                }
                .star-rating {
                    font-size: 15px;

                    i {
                        color: #f49f0b;
                    }
                }
                .price {
                    margin-top: 5px;
                    font-weight: 600;
                    display: block;
                    color: var(--optionalColor);
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .widget_colors {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                display: block;
                margin-bottom: 12px;
                border-bottom: 1px dashed #eeeeee;
                padding-bottom: 12px;

                a {
                    display: flex;
                    align-items: center;

                    span {
                        display: block;
                        font-weight: 600;
                        color: var(--optionalColor);

                        &:nth-child(1) {
                            width: 17px;
                            height: 17px;
                            font-size: 0;
                            margin-right: 8px;
                            letter-spacing: 0;
                            border-radius: 50%;
                            transition: var(--transition);

                            &.gray {
                                background-color: gray;
                            }
                            &.red {
                                background-color: red;
                            }
                            &.green {
                                background-color: green;
                            }
                            &.black {
                                background-color: black;
                            }
                            &.brown {
                                background-color: brown
                            }
                        }
                    }
                    &:hover {
                        span {
                            &:nth-child(1) {
                                transform: scale(1.2);
                            }
                        }
                    }
                }
            }
        }
    }
    &.left-widget-area {
        padding: {
            left: 0;
            right: 15px;
        };
    }
}
.billing-details {
    .form-group {
        .ngx-dropdown-container {
            .ngx-dropdown-button {
                border: 1px solid #eeeeee !important;
                margin-bottom: 0 !important;
                overflow: unset !important;
                min-height: auto !important;
                border-radius: 5px !important;
                height: 45px !important;
                line-height: 41px !important;
                display: block !important;
                color: var(--blackColor) !important;
                transition: var(--transition) !important;
                background: #f8f8f8 !important;
                font: {
                    size: var(--fontSize) !important;
                    weight: 600 !important;
                };
                padding: {
                    left: 12px !important;
                    right: 0 !important;
                    bottom: 0 !important;
                    top: 0 !important;
                };
                .nsdicon-angle-down {
                    right: 12px !important;

                    &::before {
                        height: 8px !important;
                        width: 8px !important;
                        top: 3px !important;
                        border-color: var(--mainColor) !important;
                    }
                }
                &:hover {
                    border-color: var(--mainColor) !important;
                }
            }
            .ngx-dropdown-list-container {
                border: none !important;
                width: 100% !important;
                margin-bottom: 0 !important;
                margin-top: 0 !important;
                border-radius: 0 !important;
                padding-top: 0 !important;
                padding-left: 15px !important;
                padding-bottom: 15px !important;
                background-color: var(--whiteColor) !important;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2) !important;

                .search-container {
                    input {
                        border-color: #eeeeee !important;
                    }
                }
                ul {
                    margin-top: 15px !important;
                    margin-bottom: 5px !important;
                    padding-left: 0 !important;
                    text-align: left !important;
                    
                    li {
                        position: relative !important;
                        color: var(--blackColor) !important;
                        transition: var(--transition) !important;
                        text-align: left;
                        padding: {
                            left: 0 !important;
                            right: 0 !important;
                            top: 15px !important;
                            bottom: 0 !important;
                        };
                        &:first-child {
                            padding-top: 0 !important;
                        }
                        font: {
                            size: var(--fontSize) !important;
                            weight: 500 !important;
                        };
                        &:hover {
                            color: var(--mainColor) !important;
                        }
                    }
                    &.selected-items {
                        li {
                            background-color: transparent !important;
                            color: var(--mainColor) !important;
                            margin-bottom: 2px !important;
                        }
                    }
                }
            }
        }
    }
}
.fiwan-grid-sorting {
    .ordering {
        .ngx-dropdown-container {
            width: 215px !important;
            display: inline-block !important;

            .ngx-dropdown-button {
                border: 1px solid #eeeeee !important;
                margin-bottom: 0 !important;
                overflow: unset !important;
                min-height: auto !important;
                border-radius: 5px !important;
                height: 45px !important;
                line-height: 35px !important;
                display: block !important;
                color: var(--blackColor) !important;
                transition: var(--transition) !important;
                background: #f8f8f8 !important;
                font: {
                    size: var(--fontSize) !important;
                    weight: 600 !important;
                };
                padding: {
                    left: 12px !important;
                    right: 0 !important;
                    bottom: 0 !important;
                    top: 0 !important;
                };
                .nsdicon-angle-down {
                    right: 12px !important;

                    &::before {
                        height: 8px !important;
                        width: 8px !important;
                        top: 3px !important;
                        border-color: var(--mainColor) !important;
                    }
                }
                &:hover {
                    border-color: var(--mainColor) !important;
                }
            }
            .ngx-dropdown-list-container {
                border: none !important;
                width: 100% !important;
                margin-bottom: 0 !important;
                margin-top: 0 !important;
                border-radius: 0 !important;
                padding-top: 0 !important;
                padding-left: 15px !important;
                padding-bottom: 15px !important;
                background-color: var(--whiteColor) !important;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2) !important;

                .search-container {
                    input {
                        border-color: #eeeeee !important;
                    }
                }
                ul {
                    margin-top: 15px !important;
                    margin-bottom: 5px !important;
                    padding-left: 0 !important;
                    text-align: left !important;
                    
                    li {
                        position: relative !important;
                        color: var(--blackColor) !important;
                        transition: var(--transition) !important;
                        text-align: left;
                        padding: {
                            left: 0 !important;
                            right: 0 !important;
                            top: 15px !important;
                            bottom: 0 !important;
                        };
                        &:first-child {
                            padding-top: 0 !important;
                        }
                        font: {
                            size: var(--fontSize) !important;
                            weight: 500 !important;
                        };
                        &:hover {
                            color: var(--mainColor) !important;
                        }
                    }
                    &.selected-items {
                        li {
                            background-color: transparent !important;
                            color: var(--mainColor) !important;
                            margin-bottom: 2px !important;
                        }
                    }
                }
            }
        }
    }
}
@keyframes movebounce {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0);
    }
}

/*================================================
Modal CSS
=================================================*/
.modal {
    &.right {
        .modal-dialog {
            position: fixed;
            margin: auto;
            width: 420px;
            height: 100%;
            transform: translate3d(0%, 0, 0);
        }
        .modal-content {
            height: 100%;
            overflow-y: auto;
            background-color: var(--whiteColor);
            border: none;
            border-radius: 0;

            button.close {
                float: unset;
                position: absolute;
                right: 20px;
                top: 15px;
                color: var(--blackColor);
                z-index: 2;
                background-color: transparent;
                opacity: 1;
                border: none;
                text-shadow: unset;
                box-shadow: unset;
                font-size: 40px;
                padding: 0;
                transition: var(--transition);

                &:hover {
                    color: red;
                }
            }
        }
        &.fade {
            .modal-dialog {
                right: -320px;
                transition: opacity 0.3s linear, right 0.3s ease-out;
            }
            &.show {
                .modal-dialog {
                    right: 0;
                }
            }
        }
    }
}
.sidebarModal {
    &.modal {
        .modal-body {
            text-align: center;
            padding: 80px 30px;

            .instagram-list {
                margin-top: 60px;
    
                .row {
                    margin: {
                        left: -5px;
                        right: -5px;
                    };
                    .col-lg-4 {
                        padding: {
                            left: 5px;
                            right: 5px;
                        };
                    }
                }
                .box {
                    position: relative;
                    z-index: 1;
                    overflow: hidden;
                    margin-top: 10px;
    
                    .link-btn {
                        display: block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        z-index: 3;
                    }
                    i {
                        position: absolute;
                        left: 0;
                        right: 0;
                        color: var(--whiteColor);
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 30px;
                        transition: var(--transition);
                        opacity: 0;
                        visibility: hidden;
                        z-index: 2;
                    }
                    img {
                        transition: var(--transition);
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        z-index: 1;
                        background-color: var(--mainColor);
                        transition: var(--transition);
                        opacity: 0;
                        visibility: hidden;
                    }
                    &:hover {
                        &::before {
                            opacity: 0.8;
                            visibility: visible;
                        }
                        img {
                            transform: scale(1.3);
                        }
                        i {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
            .sidebar-contact-info {
                margin-top: 60px;

                h2 {
                    margin-bottom: 0;
                    font: {
                        size: 30px;
                        weight: 600;
                    };
                    span {
                        display: block;
                        font-size: var(--fontSize);
                        margin: {
                            top: 10px;
                            bottom: 8px;
                        };
                    }
                }
            }
            .social-list {
                list-style-type: none;
                padding-left: 0;
                margin: {
                    bottom: 0;
                    top: 15px;
                };
                li {
                    display: inline-block;
                    margin-right: 8px;

                    span {
                        display: block;
                        color: var(--mainColor);
                        font: {
                            weight: 600;
                        };
                    }
                    a {
                        position: relative;
                        top: 3px;
                        font-size: 20px;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
.productsFilterModal {
    &.modal {
        .modal-body {
            padding: 0;
        }
        .modal-content {
            padding: 30px;

            button {
                &.close {
                    top: 0;
                    right: 0;
                    font-size: 17px;
                    margin-bottom: 35px;
                    text-align: left;
                    position: relative;
                    padding: 0;
                    padding-left: 25px;
                    background-color: transparent;
                    border: none;

                    i {
                        position: absolute;
                        font-size: 20px;
                        left: 0;
                        top: 0;
                    }
                }
            }
        }
    }
}
.productsQuickView {
    padding-right: 0 !important;

    .modal-dialog {
        max-width: 900px;
        margin: 0 auto;
    }
    .modal-content {
        border: none;
        padding: 40px;
        background-color: #f9f9f9;
        border-radius: 0;

        button {
            &.close {
                top: 5px;
                right: 5px;
                z-index: 1;
                outline: 0;
                padding: 0;
                margin: 0;
                opacity: 1;
                width: 35px;
                height: 35px;
                font-size: 25px;
                line-height: 41px;
                position: absolute;
                text-shadow: unset;
                border-radius: 50%;
                color: var(--blackColor);
                transition: var(--transition);
                border: none;
                padding: 0;
                background-color: var(--whiteColor);

                &:hover {
                    background-color: red;
                    color: var(--whiteColor);
                }
            }
        }
        .products-content {
            h3 {
                margin-bottom: 0;
                font-size: 22px;
            }
            .price {
                transition: var(--transition);
                margin: {
                    top: 12px;
                    bottom: 15px;
                };
                font: {
                    size: var(--fontSize);
                    weight: 600;
                };
                .old-price {
                    text-decoration: line-through;
                    color: #999999;
                    font-weight: 500;
                }
            }
            .products-review {
                margin-bottom: 15px;

                .rating {
                    display: inline-block;
                    padding-right: 5px;

                    i {
                        color: #f49f0b;
                        display: inline-block;
                    }
                }
                .rating-count {
                    display: inline-block;
                    color: var(--blackColor);
                    border-bottom: 1px solid var(--blackColor);
                    line-height: initial;
                    position: relative;
                    top: -1px;

                    &:hover {
                        color: var(--mainColor);
                        border-color: var(--mainColor);
                    }
                }
            }
            .products-info {
                list-style-type: none;
                padding-left: 0;
                margin: {
                    top: 15px;
                    bottom: 0;
                };
                li {
                    color: var(--optionalColor);
                    margin-bottom: 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    span {
                        color: var(--optionalColor);
                    }
                    a {
                        display: inline-block;
                        font-weight: 600;
                        color: var(--blackColor);

                        &:hover {
                            color: var(--mainColor);
                        }
                    }
                }
            }
            .products-add-to-cart {
                margin-top: 15px;

                .input-counter {
                    max-width: 110px;
                    min-width: 110px;
                    margin-right: 10px;
                    text-align: center;
                    display: inline-block;
                    position: relative;

                    span {
                        top: 0;
                        cursor: pointer;
                        color: #d0d0d0;
                        width: 40px;
                        height: 100%;
                        line-height: 55px;
                        font-size: 18px;
                        position: absolute;
                        background-color: transparent;
                        transition: var(--transition);

                        &.minus-btn {
                            left: 0;
                        }
                        &.plus-btn {
                            right: 0;
                        }
                        &:hover {
                            color: var(--mainColor);
                        }
                    }
                    input {
                        height: 50px;
                        outline: 0;
                        display: block;
                        border: none;
                        text-align: center;
                        width: 100%;
                        color: var(--blackColor);
                        background-color: var(--whiteColor);
                        font: {
                            size: 17px;
                            weight: 600;
                        }
                        &::placeholder {
                            color: var(--blackColor);
                        }
                    }
                    .default-btn {
                        i {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {

    body {
        font-size: 14px;
    }
    p {
        font-size: 14px;
    }
    .ptb-100 {
        padding: {
            // top: 60px;
            // bottom: 60px;
        };
    }
    .pt-100 {
        padding-top: 60px;
    }
    .pb-100 {
        padding-bottom: 60px;
    }
    .ptb-70 {
        padding: {
            top: 30px;
            bottom: 30px;
        };
    }
    .pt-70 {
        padding-top: 30px;
    }
    .pb-70 {
        padding-bottom: 30px;
    }
    .container {
        max-width: 100%;
    }
    .section-title {
        max-width: 100%;
        margin: {
            left: 0;
            right: 0;
            top: -7px;
            bottom: 40px;
        };
        h2 {
            font-size: 24px;
            // margin-top: -89px;
        }
    }
    .default-btn {
        font-size: 14px;
        padding: 11px 20px 10px 45px;

        i {
            left: 20px;
            font-size: 15px;
        }
    }
    .form-control {
        font-size: 14px;
        padding-left: 10px;
    }

    .fiwan-responsive-nav {
        .fiwan-responsive-menu {
            &.mean-container {
                .mean-nav {
                    // margin-top: 39px;

                    ul {
                        font-size: 15px;

                        li {
                            a {
                                &.active {
                                    color: var(--mainColor);
                                }
                            }
                            li {
                                a {
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }
                .navbar-nav {
                    overflow-y: scroll;
                    // height: 357px;
                    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, .1);
                }
            }
        }
        .mean-container {
            a {
                &.meanmenu-reveal {
                    color: var(--blackColor);

                    span {
                        background: var(--blackColor);
                    }
                }
            }
        }
    }

    .home-slides {
        .owl-theme {
            .owl-nav {
                left: 0;
                bottom: 0;
                bottom: 0;
                margin-top: 20px;
                position: relative;

                [class*=owl-] {
                    width: 38px;
                    height: 38px;
                    font-size: 20px;
                }
            }
        }
    }
    .home-slides-two {
        .owl-theme {
            .owl-nav {
                margin-bottom: 60px;

                [class*=owl-] {
                    width: 38px;
                    height: 38px;
                    font-size: 20px;
                }
            }
        }
    }
    .testimonials-slides {
        .owl-theme {
            .owl-nav {
                [class*=owl-] {
                    top: auto;
                    bottom: 8px;
                    transform: unset;
                    width: 35px;
                    height: 35px;
                    font-size: 20px;
                    left: 0;

                    &.owl-next {
                        left: auto;
                        right: 0;
                    }
                }
            }
        }
    }
    .products-slides {
        .owl-theme {
            .owl-nav {
                &.disabled+.owl-dots {
                    margin-top: 0;
                }
            }
            .owl-dots {
                .owl-dot {
                    span {
                        width: 15px;
                        height: 15px;
                        margin: 0 3px;
                    }
                }
            }
        }
    }
    .products-details-image-slides {
        .owl-theme {
            .owl-nav {
               opacity: 1;
               visibility: visible;

               [class*=owl-] {
                    left: 10px;
                    width: 35px;
                    height: 35px;
                    font-size: 20px;

                    &.owl-next {
                        left: auto;
                        right: 10px;
                    }
                }
            }
        }
    }

    .page-title-area {
        // margin-top: 55px;
        padding: {
            top: 27px;
            // bottom: 88px;
           
        };
        h1 {
            font-size: 24px;
            margin-bottom: 10px;
        }
        ul {
            li {
                &::before {
                    top: 1.4px;
                }
            }
        }
    }

    .billing-details {
        .form-group {
            .ngx-dropdown-container {
                .ngx-dropdown-button {
                    font-size: 14px !important;
                }
            }
        }
    }

    .fiwan-grid-sorting {
        .ordering {
            .ngx-dropdown-container {
                .ngx-dropdown-button {
                    font-size: 14px !important;
                }
            }
        }
    }

    .pagination-area {
        margin-top: 5px;

        .ngx-pagination {
            li {
                font-size: 14px;
                margin: {
                    left: 3px;
                    right: 3px;
                };
            }
        }
    }

    .widget-area {
        padding-left: 0;
        margin-top: 40px;

        .widget {
            margin-bottom: 30px;

            .widget-title {
                font-size: 17px;
                margin-bottom: 25px;
            }
        }
        .widget_fiwan_posts_thumb {
            .item {
                .info {
                    .title {
                        font-size: 15px;
                    }
                    .meta {
                        li {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
        .widget_socials_link {
            ul {
                li {
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
        .widget_buy_now {
            .content {
                padding: 15px;
                max-width: 225px;

                &::before {
                    margin: -10px;
                }
            }
        }
        .widget_categories {
            ul {
                li {
                    font-size: 14.5px;
                }
            }
        }
        .tagcloud {
            a {
                font-size: 13.5px !important;
            }
        }
        &.left-widget-area {
            padding-right: 0;
            margin: {
                top: 0;
                bottom: 40px;
            };
        }
        .widget_top_rated_products {
            .item {
                .info {
                    .title {
                        font-size: 14.5px;
                    }
                }
            }
        }
    }

    .modal {
        &.right {
            .modal-dialog {
                width: 100%;
            }
            .modal-content {
                button.close {
                    right: 20px;
                    top: 20px;
                    font-size: 25px;
                }
            }
        }
    }
    .sidebarModal {
        &.modal {
            .modal-body {
                text-align: left;
                padding: 20px;

                .instagram-list {
                    margin-top: 30px;
                }
                .sidebar-contact-info {
                    margin-top: 30px;

                    h2 {
                        font-size: 20px;

                        span {
                            font-size: 13px;
                        }
                    }
                }
                .social-list {
                    li {
                        a {
                            position: relative;
                            top: 3px;
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }
    .productsQuickView {
        .modal-dialog {
            max-width: 300px;
            margin: 0 auto;
        }
        .modal-content {
            padding: 15px;

            .products-content {
                margin-top: 25px;

                h3 {
                    font-size: 18px;
                }
                .price {
                    font-size: 14px;
                }
                .products-info {
                    li {
                        font-size: 14px;
                    }
                }
                .products-color-switch {
                    h4 {
                        font-size: 14px;
                    }
                }
                .products-size-wrapper {
                    h4 {
                        font-size: 14px;
                    }
                }
                .products-add-to-cart {
                    .input-counter {
                        input {
                            height: 43px;
                            font-size: 16px;
                        }
                        span {
                            line-height: 49px;
                        }
                    }
                }
            }
        }
    }
    .productsFilterModal {
        &.modal {
            .modal-content {
                padding: 20px;

                button {
                    &.close {
                        margin-bottom: 25px;
                        right: 0;
                        top: 0;
                        font-size: 17px;
                    }
                }
                .widget-area {
                    margin: {
                        top: 0 !important;
                        bottom: 0 !important;
                    };
                }
            }
        }
    }

}

/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {

    .container {
        max-width: 540px;
    }

    .productsQuickView {
        .modal-dialog {
            max-width: 500px;
        }
        .modal-content {
            padding: 30px;
        }
    }

}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {

    body {
        font-size: 15px;
    }
    p {
        font-size: 15px;
    }
    .ptb-100 {
        padding: {
            top: 80px;
            bottom: 80px;
        };
    }
    .pt-100 {
        padding-top: 80px;
    }
    .pb-100 {
        padding-bottom: 80px;
    }
    .ptb-70 {
        padding: {
            top: 50px;
            bottom: 50px;
        };
    }
    .pt-70 {
        padding-top: 50px;
    }
    .pb-70 {
        padding-bottom: 50px;
    }
    .container {
        max-width: 720px;
    }
    .section-title {
        max-width: 100%;
        margin: {
            left: 0;
            right: 0;
            bottom: 50px;
        };
        h2 {
            font-size: 30px;
           
        }
        p {
            max-width: 600px;
            margin: {
                left: auto;
                right: auto;
            };
        }
    }
    .default-btn {
        font-size: 15px;
        padding: 11px 25px 11px 50px;

        i {
            left: 22px;
            font-size: 18px;
            margin-top: -1px;
        }
    }

    .fiwan-responsive-nav {
        .fiwan-responsive-menu {
            &.mean-container {
                .mean-nav {
                    margin-top: 39px;

                    ul {
                        font-size: 15px;

                        li {
                            a {
                                &.active {
                                    color: var(--mainColor);
                                }
                            }
                            li {
                                a {
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }
                .navbar-nav {
                    overflow-y: scroll;
                    // height: 357px;
                    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, .1);
                }
            }
        }
        .mean-container {
            a {
                &.meanmenu-reveal {
                    color: var(--blackColor);

                    span {
                        background: var(--blackColor);
                    }
                }
            }
        }
    }

    .home-slides {
        .owl-theme {
            .owl-nav {
                left: 0;
                position: relative;
                margin-top: 30px;
            }
        }
    }
    .home-slides-two {
        .owl-theme {
            .owl-nav {
                margin-bottom: 80px;
            }
        }
    }
    .products-slides {
        .owl-theme {
            .owl-nav {
                &.disabled+.owl-dots {
                    margin-top: 0;
                }
            }
        }
    }
    .testimonials-slides {
        .owl-theme {
            .owl-nav {
                [class*=owl-] {
                    top: auto;
                    bottom: 9px;
                    transform: unset;
                    left: 0;

                    &.owl-next {
                        left: auto;
                        right: 0;
                    }
                }
            }
        }
    }
    .products-details-image-slides {
        .owl-theme {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .page-title-area {
        margin-top: 55px;
        
        h1 {
            font-size: 30px;
        }
    }

    .widget-area {
        padding-left: 0;
        margin-top: 40px;

        .widget {
            margin-bottom: 30px;

            .widget-title {
                font-size: 19px;
                margin-bottom: 25px;
            }
        }
        &.left-widget-area {
            padding-right: 0;
            margin: {
                top: 0;
                bottom: 40px;
            };
        }
    }

    .productsQuickView {
        .modal-dialog {
            max-width: 740px;
        }
        .modal-content {
            padding: 20px;
        }
    }
    .productsFilterModal {
        &.modal {
            .modal-content {
                .widget-area {
                    margin-bottom: 0;
                }
            }
        }
    }

}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {

    .section-title {
        h2 {
            font-size: 32px;
        }
    }
    .container {
        max-width: 960px;
    }
    .default-btn {
        font-size: 15px;
    }

    .fiwan-responsive-nav {
        .fiwan-responsive-menu {
            &.mean-container {
                .mean-nav {
                    margin-top: 46px;

                    ul {
                        font-size: 15px;

                        li {
                            a {
                                &.active {
                                    color: var(--mainColor);
                                }
                            }
                            li {
                                a {
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }
                .navbar-nav {
                    overflow-y: scroll;
                    // height: 357px;
                    box-shadow: 0 7px 13px 0 rgba(0, 0, 0, .1);
                }
            }
        }
        .mean-container {
            a {
                &.meanmenu-reveal {
                    color: var(--blackColor);

                    span {
                        background: var(--blackColor);
                    }
                }
            }
        }
    }

    .home-slides {
        .owl-theme {
            .owl-nav {
                left: 0;
            }
        }
    }

    .widget-area {
        padding-left: 0;

        .widget_buy_now {
            .content {
                max-width: 220px;
            }
        }
        &.left-widget-area {
            padding-right: 0;
        }
    }

    .page-title-area {
        margin-top: 65px;
    }

}

/* Min width 1200px to Max width 1355px */
@media only screen and (min-width: 1200px) and (max-width: 1355px) {

    .container {
        max-width: 1140px;
    }

    .home-slides {
        .owl-theme {
            .owl-nav {
                left: 50px;
            }
        }
    }

}

/* Min width 1550px */
@media only screen and (min-width: 1550px) {

    .home-slides {
        .owl-theme {
            .owl-nav {
                left: 100px;
            }
        }
    }

}@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

/* Color Variables */
:root {
  --white: #fff;
  --black: #222;
  --green: #6cbe02;
  --grey1: #f0f0f0;
  --grey2: #e9d7d3;
}

/* Basic Reset */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
  background-color: var(--white);
  color: var(--black);
  font-weight: 400;
  font-style: normal;
}

a {
  text-decoration: none;
  color: var(--black);
}

li {
  list-style: none;
}

img {
  width: 100%;
}

.container {
  max-width: 114rem;
  margin: 0 auto;
  padding: 0 3rem;
}

.d-flex {
  display: flex;
  align-items: center;
}

/* 
=================
Header
=================
*/

.header {
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  z-index: 0;
}

/* 
=================
Navigation
=================
*/
.navigation {
  position: relative;
  z-index: 2;
  height: 6rem;
  line-height: 6rem;
}

.nav-center {
  justify-content: space-between;
}

.logo {
  color: white;
}

.nav-center .nav-item:not(:last-child) {
  margin-right: 0.5rem;
}

.nav-center .nav-link {
  font-size: 1.8rem;
  padding: 1rem;
  color: white;
}

.nav-center .nav-link:hover {
  color: var(--green);
}

.nav-center .hamburger {
  display: none;
  font-size: 2.3rem;
  color: var(--black);
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .nav-list {
    position: fixed;
    top: 12%;
    left: -35rem;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    background-color: white;
    height: 100%;
    width: 0%;
    max-width: 35rem;
    z-index: 100;
    transition: all 300ms ease-in-out;
  }

  .nav-list.open {
    left: 0;
    width: 100%;
  }

  .nav-list .nav-item {
    margin: 0 0 1rem 0;
    width: 100%;
  }

  .nav-list .nav-link {
    font-size: 2rem;
    color: var(--black);
  }

  .nav-center .hamburger {
    display: block;
    color: white;
    font-size: 3rem;
  }
}

/* Hero */

.hero,
.heroslider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.hero img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  content: "";
}

.swiper-button-next {
  right: -50px;
}

.swiper-button-prev {
  left: -50px;
}

.header:hover .swiper-button-next {
  right: 40px;
}
.header:hover .swiper-button-prev {
  left: 40px;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  padding: 0.5rem 2.3rem;
  transition: all 500ms ease-in-out;
}

.swiper-icon {
  font-size: 5rem;
  color: white;
}

.header .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  z-index: 1;
  text-align: center;
}

.header .content h1 {
  font-size: 7rem;
  color: #ffffff;
  margin-bottom: 4rem;
}

.header .search {
  width: 70rem;
  margin: 0 auto;
  padding: 1rem 2rem;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .search input {
  width: 100%;
  padding: 1.5rem 0;
  text-indent: 1rem;
  font-size: 1.6rem;
  margin-right: 1rem;
  outline: none;
  border: none;
}

.header .search a {
  display: inline-block;
  padding: 1.5rem 4rem;
  background-color: var(--green);
  color: white;
  border-radius: 0.5rem;
}

@media (max-width: 996px) {
  .header .content h1 {
    font-size: 5rem;
    margin-bottom: 3rem;
  }

  .header .search {
    width: 50rem;
  }
}

@media (max-width: 567px) {
  .header .content h1 {
    font-size: 3rem;
    margin-bottom: 2rem;
  }

  .header .search {
    width: 100%;
  }

  .header .search input {
    padding: 1rem 0;
    font-size: 1.3rem;
  }

  .header .search a {
    padding: 1rem;
  }

  .header:hover .swiper-button-next {
    right: 5px;
  }
  .header:hover .swiper-button-prev {
    left: 5px;
  }

  .swiper-icon {
    font-size: 3rem;
  }

  .swiper-button-next,
  .swiper-button-prev {
    padding: 0.2rem 2.3rem;
  }
}

/* Rent Properties */
.section {
  padding: 10rem 0;
}

.section.rent {
  background-color: #f0f4f7;
}

.rent-center {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  gap: 4rem 2rem;
}

.title {
  text-align: center;
  margin-bottom: 5rem;
  padding: 1rem;
}

.title h1 {
  font-weight: 100;
  font-size: 6rem;
  margin-bottom: 1rem;
}

.rent .box {
  transition: all 300ms ease-in-out;
  background-color: white;
}

.rent .box:hover {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

.rent .box .top {
  padding: 1rem;
  position: relative;
  height: 20rem;
  transition: all 300ms ease-in-out;
}

.rent .box:hover .top {
  cursor: pointer;
}

.rent .box .top img {
  height: 100%;
  object-fit: cover;
}

.rent .box .overlay {
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
  background-color: white;
  height: 20rem;
  width: 100%;
}

.rent .box .overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease-in-out;
}

.rent .box .top:hover .overlay::after {
  opacity: 1;
  visibility: visible;
}

.rent .box .pos {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.rent .box .pos span {
  display: inline-block;
  font-size: 1.3rem;
  color: white;
  margin-right: 0.5rem;
  padding: 0.3rem;
  border-radius: 0.3rem;
}

.rent .box .pos span:first-child {
  background-color: #43c370;
}

.rent .box .pos span:last-child {
  background-color: #e0203b;
}

.rent .box .bottom {
  padding: 1.5rem;
}

.rent .box .bottom p {
  font-size: 2rem;
  font-weight: 500;
  color: #555;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}

.rent .box .bottom div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  color: #555;
}

.rent .box .bottom div span {
  font-size: 1.8rem;
}

.rent .box:hover .bottom p {
  color: #006eff;
}

.rent .box .bottom div i {
  font-size: 2.5rem;
}

@media (max-width: 768px) {
  .title h1 {
    font-size: 4rem;
  }
}

@media (max-width: 567px) {
  .title h1 {
    font-size: 3rem;
  }
}

/* Grid Properties */
.wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
}

.box {
  border-radius: 5px;
  overflow: hidden;
}

.box img {
  height: 100%;
  object-fit: cover;
  transition: all 500ms ease-in-out;
}

.box:hover img {
  transform: scale(1.1);
}

.box1 {
//   grid-column: 1 / span 2;
  height: 35rem;
}
.box2 {
  grid-column: 3;
  grid-row: 1 / span 2;
}
.box3 {
  grid-column: 1;
  grid-row: 2;
}
.box4 {
  grid-column: 2;
  grid-row: 2;
}

@media (max-width: 768px) {
  .box1 {
    grid-column: 1 / span 3;
    height: 20rem;
  }
  .box2 {
    grid-column: 3;
    grid-row: 2;
    height: 20rem;
  }
}

@media (max-width: 567px) {
  .wrapper .box {
    height: 15rem;
  }
}

/* Contact */
.contact {
//   background: url("./images/pic5.jpg") no-repeat fixed;
  color: var(--white);
  padding: 10rem 20rem;
}

.contact .row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.contact .row .col h2 {
  margin-bottom: 1.5rem;
  font-size: 3rem;
}

.contact .row .col p {
  font-size: 1.8rem;
  width: 70%;
  margin-bottom: 2rem;
}

.btn-1 {
  background-color: var(--green);
  color: var(--white);
  display: inline-block;
  border-radius: 1rem;
  font-size: 1.8rem;
  padding: 1.5rem 5rem;
}

.contact form div {
  position: relative;
  margin: 0 auto;
}

.contact form input {
  font-family: "Roboto", sans-serif;
  text-indent: 2rem;
  font-size: 1.8rem;
  width: 100%;
  border-radius: 1rem;
  padding: 2rem 0;
  outline: none;
  border: none;
}

.contact form a {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--green);
  color: white;
  margin: 0.5rem;
  padding: 1.7rem 5rem;
  border-radius: 1rem;
}

@media only screen and (max-width: 996px) {
  .contact {
    padding: 8rem 8rem;
  }
}

@media only screen and (max-width: 768px) {
  .contact .row {
    grid-template-columns: 1fr;
    gap: 5rem 0;
  }
}

@media only screen and (max-width: 567px) {
  .contact {
    padding: 8rem 1rem;
  }
}

/* Footer */
.footer {
  padding: 7rem 1rem;
  background-color: #303441;
}

footer .row {
//   display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 99.6rem;
  margin: 0 auto;
}

.footer .col {
  flex-direction: column;
  color: var(--white);
  align-items: flex-start;
}

.footer .col:last-child {
  flex-direction: row;
  justify-content: center;
}

.footer .col:last-child span {
  font-size: 2.5rem;
  margin-right: 0.5rem;
  color: var(--white);
}

.footer .col a {
  color: var(--white);
  font-size: 1.5rem;
  padding: 0.5rem;
  font-weight: 300;
}

.footer .col h4 {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 567px) {
  footer .row {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }
}